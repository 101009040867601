import React from "react";
import { useGlitch } from "react-powerglitch";
import styled from "styled-components";
import background from "../assets/background.jpg";
import logo from "../assets/logo.svg";
import Events from "./Events";

const LandingPage: React.FC = () => {
  const glitch = useGlitch({
    playMode: "hover",
  });

  return (
    <LandingPageContainer>
      <Logo src={logo} alt="Music Label Logo" ref={glitch.ref} />
      <GrooveHeadline>We bring the Groove</GrooveHeadline>
      <EventsContainer>
        <Events></Events>
      </EventsContainer>
    </LandingPageContainer>
  );
};

export default LandingPage;

const GrooveHeadline = styled.h1`
  font-size: 4rem;
  color: white;
  font-family: "Ashing";
`;

const LandingPageContainer = styled.div`
  background-image: url(${background});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    margin-top: 12.5rem;
  }
`;

const EventsContainer = styled.div`
  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }
`;

const Logo = styled.img`
  width: 750px;
  height: auto;
  filter: drop-shadow(0 0 1rem purple);
  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    max-width: 80%; /* Smaller width on medium screens */
  }
`;
