import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { pastEvents, upcomingEvents } from "../data";
import { Event } from "../types";

const Events: React.FC = () => {
  const navigate = useNavigate();
  const openInNewTab = (event: Event) => {
    navigate("/details", { state: event });
  };

  return (
    <EventsContainer id="events">
      <h2>Upcoming Events</h2>
      <hr />
      {upcomingEvents.map((event: Event, index: number) => (
        <ImageContainer key={event.title}>
          <EventImage
            onClick={() => openInNewTab(event)}
            src={event.image}
            alt={event.title}
          />
        </ImageContainer>
      ))}
      <h2>Past Events</h2>
      <hr />
      {pastEvents.map((event: Event, index: number) => (
        <ImageContainer key={event.title}>
          <EventImage
            // ref={glitch.ref}
            onClick={() => openInNewTab(event)}
            key={event.title}
            src={event.image}
            alt={event.title}
          />
        </ImageContainer>
      ))}
    </EventsContainer>
  );
};

export default Events;

const ImageContainer = styled.div`
  width: 500px;
  max-width: 800px; /* Set a maximum width for desktops */
  margin: 0 auto; /* Center the container */
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    max-width: 80%; /* Smaller width on medium screens */
  }
`;

const EventsContainer = styled.div`
  padding: 2rem;
  color: white;
  h2 {
    margin-bottom: 1rem;
  }
`;

const EventImage = styled.img`
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  padding: 1rem;
  &:hover {
    background: purple;
  }
`;
