import React, { useState } from "react";
import { CartItem } from "../../types";
import "./Cart.css";

interface CartProps {
  cart: CartItem[];
  onClose: () => void;
  setCart: React.Dispatch<React.SetStateAction<CartItem[]>>;
}

const Cart: React.FC<CartProps> = ({ cart, onClose, setCart }) => {
  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
  });

  const totalAmount = cart.reduce((total, item) => total + item.price, 0);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckout = () => {
    const isAddressValid = Object.values(address).every(
      (field) => field.trim() !== ""
    );

    if (!isAddressValid) {
      alert("Bitte fülle alle Adressfelder aus.");
      return;
    }

    alert("Bestellung abgeschlossen!");
    localStorage.removeItem("cart");
    setCart([]);
    onClose();
  };

  const handleRemoveItem = (id: string) => {
    const updatedCart = cart.filter((item) => item.id !== id);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <div className="cartContainer">
      <h2>Warenkorb</h2>
      <div className="cartItems">
        {cart.map((item) => (
          <div
            key={item.id}
            className="cartItem"
            onClick={() => handleRemoveItem(item.id)}
          >
            <img
              src={item.imageUrl}
              alt={item.name}
              className="cartItemImage"
            />
            <div className="cartItemDetails">
              <p className="cartItemName">{item.name}</p>
              <p>Größe: {item.selectedSize}</p>
              <p>Preis: €{item.price.toFixed(2)}</p>
            </div>
          </div>
        ))}
      </div>
      <hr />
      <p className="totalAmount">Gesamtbetrag: €{totalAmount.toFixed(2)}</p>
      <h3>Adresse angeben</h3>
      <div className="addressForm">
        <input
          type="text"
          name="firstName"
          placeholder="Vorname"
          value={address.firstName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="lastName"
          placeholder="Nachname"
          value={address.lastName}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="street"
          placeholder="Straße"
          value={address.street}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="houseNumber"
          placeholder="Hausnummer"
          value={address.houseNumber}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="postalCode"
          placeholder="Postleitzahl"
          value={address.postalCode}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="city"
          placeholder="Ort"
          value={address.city}
          onChange={handleInputChange}
        />
      </div>
      <button className="checkoutButton" onClick={handleCheckout}>
        Bestellung abschließen
      </button>
      <button className="closeButton" onClick={onClose}>
        Zurück zum Shop
      </button>
    </div>
  );
};

export default Cart;
