import React, { useState } from "react";
import { Product } from "../../types";
import "./ProductComponent.css";
import "./Shop.css";

interface ProductProps {
  product: Product;
  handleAddToCart: (product: Product, selectedSize: string) => void;
}

const ProductComponent: React.FC<ProductProps> = ({
  product,
  handleAddToCart,
}) => {
  const [size, setSize] = useState(product.sizes[0]);
  const handleAddToCartClick = () => {
    handleAddToCart(product, size);
  };

  return (
    <div className="productContainer">
      <h2>{product.name}</h2>
      <img src={product.imageUrl} alt={product.name} className="productImage" />
      <p>{product.description}</p>
      <p>Preis: €{product.price.toFixed(2)}</p>
      <div className="addContainer">
        <select value={size} onChange={(e) => setSize(e.target.value)}>
          {product.sizes.map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </select>

        <button onClick={handleAddToCartClick}>Zum Warenkorb hinzufügen</button>
      </div>
    </div>
  );
};

export default ProductComponent;
