import React from "react";
import { useGlitch } from "react-powerglitch";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Menu: React.FC = () => {
  const glitch = useGlitch({
    playMode: "hover",
  });

  return (
    <Nav>
      <NavItem ref={glitch.ref} to="/">
        Home
      </NavItem>
      <NavItem ref={glitch.ref} to="/events">
        Events
      </NavItem>
      <NavItem ref={glitch.ref} to="/vermietung">
        Vermietung
      </NavItem>
      <NavItem ref={glitch.ref} to="/shop">
        Shop
      </NavItem>
      <NavItem ref={glitch.ref} to="/groovecast">
        Groovecast
      </NavItem>
    </Nav>
  );
};

export default Menu;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6); /* Dark purple background */
  backdrop-filter: blur(10px); /* Blur effect */
  padding: 1rem;
  z-index: 1000;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const NavItem = styled(Link)`
  color: white;
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.2rem;

  &:hover {
    color: purple;
  }

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;
