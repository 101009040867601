import React from "react";
import "./Vermietung.css";

const Vermietung: React.FC = () => {
  return (
    <div className="vermietungContainer">
      <h1>... in Progress</h1>
    </div>
  );
};

export default Vermietung;
