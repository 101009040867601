const API_BASE_URL = "http://localhost:3000/api";

export const createOrder = async (totalAmount: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/orders`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ totalAmount }),
    });

    const data = await response.json();
    return data; // Gibt die Antwort zurück (einschließlich Links)
  } catch (error) {
    console.error("Error creating order:", error);
    throw new Error("Bestellung konnte nicht erstellt werden.");
  }
};

export const getProductsAvanti = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/products-avanti`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Fehler beim Laden der Produkte");
    }

    const products = await response.json();
    return products; // Gibt die Produktliste zurück
  } catch (error) {
    console.error("Error fetching products:", error);
    throw new Error("Produkte konnten nicht geladen werden.");
  }
};
