import React from 'react';
import styled from 'styled-components';

type CardProps = {
  id: string;
  title: string;
  link: string;
  image: string;
};

const Card = (card: CardProps): JSX.Element => {
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <CardContainer id="card">
      <CardImage
        onClick={() => openInNewTab(card.link)}
        key={card.title}
        src={card.image}
        alt={card.title}
      />
    </CardContainer>
  );
};

export default Card;

const CardContainer = styled.div`
  margin: 0 auto; /* Center the container */
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    max-width: 100%; /* Full width on small screens */
  }

  @media (min-width: 601px) and (max-width: 1200px) {
    max-width: 80%; /* Smaller width on medium screens */
  }
  h2 {
    margin-bottom: 1rem;
  }
`;

const CardImage = styled.img`
  border-radius: 10px;
  background-color: white;
  margin: 0.5rem;
  padding: 0.15rem;
  border-color: aliceblue;
  &:hover {
    background: purple;
  }
`;
